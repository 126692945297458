import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const SpeedPage = () => (
  <Layout>
    <SEO
      title="Fast Golf Scores | Speed Comparison"
      description="Golf scores don't need to be so slow"
    />
    <h2>Golf scores don&apos;t need to be slow</h2>
    <p>
      Fast golf scores was started after frustration just trying to get the
      latest scores. Look at just how long it takes to load a golf leaderboard
      across the major golf websites.
    </p>
    <table>
      <thead>
        <tr>
          <th>Site</th>
          <th>Time to interactive</th>
          <th>Download Size</th>
          <th>Goolge Page Speed</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Pgatour.com</td>
          <td>21.6 sec</td>
          <td>2.4 MB </td>
          <td>13/100</td>
          {/*  https://developers.google.com/speed/pagespeed/insights/?url=https%3A%2F%2Fwww.pgatour.com%2Fleaderboard.html
          https://www.webpagetest.org/result/190309_KS_8c7cc2086d198a45dcb963817dbccb18/ */}
        </tr>
        <tr>
          <td>Golf.com</td>
          <td>29.9 sec</td>
          <td>2.5 MB</td>
          <td>6/100</td>
          {/*

            https://www.webpagetest.org/result/190309_YK_96c23aa72694a9d88ec1856cb70d0422/
            https://developers.google.com/speed/pagespeed/insights/?url=https%3A%2F%2Fwww.golf.com%2Fleaderboard%2F2019%2Fpga%2Farnold-palmer-invitational-presented-by-mastercard
            */}
        </tr>
        <tr>
          <td>Espn.com</td>
          <td>18.8 sec</td>
          <td>2.8 MB</td>
          <td>29/100</td>
          {/*
            http://www.espn.com/golf/leaderboard
            https://developers.google.com/speed/pagespeed/insights/?url=http%3A%2F%2Fwww.espn.com%2Fgolf%2Fleaderboard
            https://www.webpagetest.org/result/190309_MD_74cbfd55447fc80122a3b231fbcaee69/

            */}
        </tr>
        <tr style={{ backgroundColor: "#fff158" }}>
          <td>Fastgolfscores.com</td>
          <td>2.4s sec</td>
          <td>0.1 MB</td>
          <td>99/100</td>
          {/*
          https://www.webpagetest.org/result/190309_E9_c7c20494ba864d17b5beab6c23f13904/
          https://developers.google.com/speed/pagespeed/insights/?url=https%3A%2F%2Fzen-lovelace-67c9d0.netlify.com%2F
          */}
        </tr>
      </tbody>
    </table>
  </Layout>
)

export default SpeedPage
